.main-area {
  width: 100%;
  background-color: #f9fbfd;
  position: relative;
  flex: 1;
  flex-direction: column;
  display: flex;
  z-index: 9;
  padding-left: 280px;
  min-height: 100vh;
  padding-right: 1rem;

  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0;
  }
}

.chart_type_view {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;
  gap: 15px;
}

.chart_view_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // flex-wrap: wrap;
  flex-direction: column;

  .chart_container {
    width: 100%;
    display: flex;
    gap: 20px;
  }

  .chart_div {
    width: 100%;
    gap: 20px;
  }

  .chart_container_memVsFault {
    width: 100%;
    display: flex;
    gap: 20px;
  }

  .chart_container_specificPool {
    width: 100%;
    display: flex;
    gap: 20px;
  }

  .chart_dropDown {
    display: flex;
  }

  .chart_dropDown_name {
    color: #0000FF;
    padding-right: 10px;
    margin-block: auto;

  }

  .chat_main {
    background: #fff;
    margin: 10px 0;
    border-radius: 8px;
    // box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    padding: 0.75rem;
    min-height: 25rem;
    flex: 1 1 auto;
    border: 1px solid #edf2f9;

    &.hide-chart {
      display: none;
    }

    &.no_data {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media only screen and (max-width: 1024px) {
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      overflow: auto;
    }
  }

  .chat_main1 {
    background: #fff;
    margin: 10px 0;
    border-radius: 8px;
    // box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    padding: 0.75rem;
    min-height: 25rem;
    flex: 1 1 auto;
    border: 1px solid #edf2f9;
    width: 26px;
    padding-top: 35px;
  }
}

.table_wrapper {
  background: #fff;
  border-radius: 8px;
  // box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
  border: 1px solid #edf2f9;
  text-align: center;
  padding: 0.75rem;
  margin: 0;
  margin-bottom: 1rem;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    overflow: auto;
  }

  table {
    margin: 0;

    .findings_col {
      text-align: left;

      &>div {
        margin-bottom: 0.75rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .Good {
        color: #3ac47d;
        font-weight: bold;
      }

      .Info {
        color: #16aaff;
        font-weight: bold;
      }

      .Warning {
        color: #f7b924;
        font-weight: bold;
      }

      .Danger,
      .Critical {
        color: #d92550;
        font-weight: bold;
      }

      .Black {
        color: #000;
        font-weight: bold;
      }
    }

    td span {
      padding: 0.4rem 0.6rem;
      border-radius: 6px;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;

      &.Good {
        background-color: #3ac47d;
        color: #fff;
      }

      &.Info {
        background-color: #16aaff;
        color: #fff;
      }

      &.Warning {
        background: #f7b924;
        color: #000;
      }

      &.Danger,
      &.Critical {
        color: #fff;
        background: #d92550;
      }
    }
  }
}

.is-sticky {
  position: sticky;
  top: 0px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.is-sticky-top {
  position: Fixed;
  top: 230px;
  // z-index: 999;
  width: 100%;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.sidebar {
  padding: 1px
}

.topbar {
  padding: 1px;
  z-index: 9999;
  background-color: ghostwhite;
  width: 100%;

  @media only screen and (min-width:1342px) and (max-width:1920px) {
    max-width: 1396px;
  }

  @media only screen and (min-width:1266px) and (max-width:1340px) {
    max-width: 1280px;
  }

  @media only screen and (min-width:760px) and (max-width:1265px) {
    max-width: 800px;
  }
}

.noDataStyle {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  border: 2px solid grey;
  min-height: 200px;
  // padding: 20px;
  margin-left: 160px;
  margin-right: 160px;
}

.noDataCenterStyle {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  border: 2px solid grey;
  min-height: 200px;
  padding-top: 83px;
  display: block;
  margin: auto;
  // padding: 20px;
  // margin-left: 160px;
  // margin-right: 160px;
}

.p-button {
 height: 40px;
}


.timeLineStyle {

  // width: 608px;
  // height: 200px; 
  @media only screen and (min-width:1342px) and (max-width:1920px) {
    max-width: 900px;
    height: 200px;
  }

  @media only screen and (min-width:1266px) and (max-width:1340px) {
    width: 648px;
    height: 200px;
  }

  @media only screen and (min-width:760px) and (max-width:1265px) {
    width: 708px;
    height: 200px;
  }
}

.header_size {
  .p-datatable-header {
    // padding: 32px;
    padding-block: 52px; //32
  }
}

/* System and What's changed buttons */

.systemWhatChangedStyle {
  display: flex;
  padding: 1em;
}

.systemButton {
  color: red;
}

.wrapDataTables {
  display: flex; //table
  overflow: auto;
  width: 100%;
  height: 100%; /* need to set height for this to work in Chrome */
}

.performance_wrapper {
  .table_wrapper {
    .p-datatable {
      margin-bottom: 2rem;
    }

    table {

      td:nth-child(1),
      td:nth-child(2) {
        width: 20%;
      }
    }

  }
}

.tableAlign td {
  vertical-align: middle;
  font-size: 14px;
}

.tableAlign.table-striped tbody tr:nth-of-type(odd) {
  background: #ffffff00 !important;
}

table.table.table-bordered.table-striped.tableAlign {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  // font-size: 14px;
}

.anotherHeader {
  border-bottom: solid #dee2e6 0.15rem;
  border-spacing: inherit;
  padding: 0.35em 0;
}

.report_details_wrapper {
  .chart_view_wrapper {
    .chat_main {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 0;
      margin-bottom: 1rem;
      min-height: 30rem;
    }
  }
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  overflow: hidden;
  background: #e9ecef;
}

.d-none {
  display: none;
}

.badge_count_name {
  display: inline-flex;
  margin-left: 10px;
  border-radius: 22px;
  padding: 4px 10px;
  padding-left: 5px;

  .badge {
    margin: 0;
    min-width: 22px;
    min-height: 22px;
    margin-right: 5px;
    background-color: #fff;
    color: #000;
    border-radius: 22px;
  }

  &.info{
    background-color: #16aaff;
    color: #fff;
  }

  &.critical {
    background-color: #d82550;
    color: #fff;
  }

  &.good {
    background-color: #2b8456;
    color: #fff;
  }

  &.warning {
    background-color: #f7b924;
    color: #000;
  }
}

.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .label {
    margin-top: 10px;
    font-size: 1.1rem;
  }
}

.nested-table {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  position: relative;
  margin: 0;
  overflow: hidden;

  thead {
    tr {
      background: #75c5f0;
    }
  }

  tr {
    border-width: 0;
  }

  td:last-child() {
    border-width: 0;
  }
}

.nested-table-td {
  background: #f8fbfd !important;
}

.Toastify__progress-bar {
  background-color: #0093dd !important;
}

.noErrorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-msg {
  font-size: 10px;
  color: red;
  margin: 0;
}

.pi-arrow-left {
  font-size: 13px;
  font-weight: 600;
}

.p-datatable {
  width: 100%;
}

.custom-tooltip {
  font-size: 13px;
  margin: 1rem 0;
  color: #000;

  .pi {
    font-size: 13px;
  }
}

/* Profile Page Coming Soon window style */

@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

bodyOfComingSoon {
  /* color: ; */
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.AppOfComingSoon {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: #ddd; /*rgb(49, 49, 177) */
  background: linear-gradient(
    0deg,
      rgb(225, 225, 228) 0%,
      rgb(213, 213, 218) 50%,
    rgba(227, 227, 231, 0.6) 100%
  );
}

.ContainerOfComingSoon {
  width: 100%;
  margin: 15% auto;

  @media (min-width: 768px) {
    .containerOfComingSoon {
      width: 1100px;
    }

    h1 {
      font-size: 58px;
    }
  }
}

.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

.table_wrapper_event {
  background: #fff;
  border-radius: 8px;
  // box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
  border: 1px solid #edf2f9;
  text-align: center;
  padding: 0.75rem;
  margin-left: 10px;
  margin-bottom: 1rem;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    overflow: auto;
  }
}

.table_wrapper_symbol {
  filter: brightness(75%);
  text-transform: lowercase !important;
  padding: 0.2rem !important;
  font-size: 0.85rem !important;
}

.table_wrapper_date {
  filter: brightness(85%);
  text-transform: uppercase !important;
  padding: 0.2rem !important;
  font-size: 0.85rem !important;
}

.table_wrapper_label {
  filter: brightness(85%);
  text-transform: capitalize !important;
  padding: 0.2rem !important;
  font-size: 1rem !important;
}

.printTable {
  width: 100% !important;
  border-collapse: separate !important;
  border-spacing: 5em !important;
}


