.cron-scheduler {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 5px;
    width: 100%;
  }
  
  .cron-scheduler select,
  .cron-scheduler input,
  .cron-scheduler button {
    padding: 10px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    background-color: #ffffff;
  }
  
  .cron-scheduler button {
    background-color: #ff5a5f;
    color: #ffffff;
    cursor: pointer;
  }
  
  .day-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .days {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ebebeb;
  }
  
  .days.disabled {
    background-color: #ebebeb;
    color: #9e9e9e;
    cursor: not-allowed;
    border: 1px solid #ebebeb;
  }
  
  .days.selected {
    background-color: #ff5a5f;
    color: #ffffff;
  }
  